import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const carouselWrappers = document.querySelectorAll('.statistics-block');

  if (0 < carouselWrappers.length) {
    carouselWrappers.forEach((carouselWrapper) => {
      new Swiper(carouselWrapper.querySelector('.statistics-block__carousel'), {
        slidesPerView: 1,
        slidesPerGroup: 1,
        centerInsufficientSlides: true,

        breakpoints: {
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          1200: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
        },

        pagination: {
          el: carouselWrapper.querySelector(
            '.statistics-block__carousel-navigation .swiper-pagination'
          ),
          type: 'fraction',
        },

        navigation: {
          prevEl: carouselWrapper.querySelector(
            '.statistics-block__carousel-navigation .swiper-button-prev'
          ),
          nextEl: carouselWrapper.querySelector(
            '.statistics-block__carousel-navigation .swiper-button-next'
          ),
        },

        a11y: {
          prevSlideMessage: _x(
            'See previous slide',
            'slider previous label',
            'vtx'
          ),
          nextSlideMessage: _x('See next slide', 'slider next label', 'vtx'),
          paginationBulletMessage: _x(
            'Go to slide {{index}}',
            'slide pagination label',
            'vtx'
          ),
          slideLabelMessage: _x(
            'Slide {{index}} of {{slidesLength}}',
            'slide label',
            'vtx'
          ),
        },
      });
    });
  }
});
